export const APPLICATION_STATUSES =  {
  DRAFT: {
    text: 'Черновик',
    value: 'draft',
  },
  APPROVING: {
    text: 'На согласовании у Администратора заявки',
    value: 'on_approving',
  },
  APPROVING_WORKSHOP: {
    text: 'На согласовании у Согласующего',
    value: 'on_workshop_reviewer_approving',
  },
  REWORK_WORKSHOP: {
    text: 'На доработке (после Согласующего)',
    value: 'on_workshop_reviewer_rework',
  },
  FINISH_WORKSHOP: {
    text: 'Закрыта Согласующим',
    value: 'finished_by_workshop_reviewer',
  },
  REWORK: {
    text: 'На доработке (после Администратора заявки)',
    value: 'on_rework',
  },
  APPROVED: {
    text: 'В работе у исполнителя',
    value: 'approved',
  },
  FINISH: {
    text: 'Закрыта',
    value: 'finished',
  },
  FINISH_REQUEST: {
    text: 'Запрос на закрытие',
    value: 'requested_to_finish',
  },
  FINISHED_BY_REVIEWER: {
    text: 'Закрыта Администратором заявки',
    value: 'finished_by_reviewer',
  },
  PROLONGATION: {
    text: 'На продлении',
    value: 'on_prolongation',
  },
  FINISHED_BY_INITIATOR: {
    value: 'finished_by_initiator',
    text: 'Закрыта заявителем',
  },
  FINISHED_BY_INSPECTOR_FAILED: {
    value: 'finished_by_inspector_failed',
    text: 'Закрыто (инспектор не выполнил работу)',
  },
  REQUESTED_TO_REVOKE_INSPECTOR: {
    text: 'Запрос на смену инспекционной компании',
    value: 'requested_to_revoke_inspector',
  },
  AWAIT_REPLACEMENT_INSPECTOR: {
    text: 'Ожидание смены инспекционной компании',
    value: 'await_replacement_inspector',
  },
  REQUESTED_TO_REVOKE_SLAVE_INSPECTOR: {
    text: 'Запрос на смену инспектора',
    value: 'requested_to_revoke_slave_inspector',
  },
  AWAIT_REPLACEMENT_SLAVE_INSPECTOR: {
    text: 'Ожидание смены инспектора',
    value: 'await_replacement_slave_inspector',
  },
};

export const APPLICATION_ACTIONS = {
  TO_APPROVE: {
    value: 'to_approve',
    text: 'Отправить на согласование',
  },
  TO_REWORK: {
    value: 'to_rework',
    text: 'Отправить на доработку',
  },
  APPROVE: {
    value: 'approve',
    text: 'Согласовать',
  },
  REQUEST_TO_FINISH: {
    value: 'request_to_finish',
    text: 'Отправить запрос на закрытие',
  },
  PROLONG: {
    value: 'prolong',
    text: 'Продлить',
  },
  APPROVE_FOR_FINISH: {
    value: 'approve_for_finish',
    text: 'Согласовать закрытие',
  },
  FINISH: {
    value: 'finish',
    text: 'Закрыть',
  },
  FINISH_BY_REVIEWER: {
    value: 'finish_by_reviewer',
    text: 'Закрыть',
  },
  TO_WORKSHOP_REVIEWER_APPROVE: {
    value: 'to_workshop_reviewer_approve',
    text: 'Отправить на согласование',
  },
  TO_WORKSHOP_REVIEWER_REWORK: {
    value: 'to_workshop_reviewer_rework',
    text: 'Отправить на доработку'
  },
  FINISHED_BY_WORKSHOP_REVIEWER: {
    value: 'finished_by_workshop_reviewer',
    text: 'Закрыть',
  },
  APPROVE_TO_REVOKE_INSPECTOR: {
    value: 'approve_to_revoke_inspector',
    text: 'Согласовать отзыв инспекционной компании',
  },
  REJECT_TO_REVOKE_INSPECTOR: {
    value: 'reject_to_revoke_inspector',
    text: 'Отказать в отзыве инспекционной компании',
  },
  REQUEST_TO_REVOKE_INSPECTOR: {
    value: 'request_to_revoke_inspector',
    text: 'Запрос на отзыв инспекционной компании',
  },
  APPROVE_TO_REVOKE_SLAVE_INSPECTOR: {
    value: 'approve_to_revoke_slave_inspector',
    text: 'Согласовать отзыв инспектора',
  },
  REJECT_TO_REVOKE_SLAVE_INSPECTOR: {
    value: 'reject_to_revoke_slave_inspector',
    text: 'Отказать в отзыве инспектора',
  },
  REQUEST_TO_REVOKE_SLAVE_INSPECTOR: {
    value: 'request_to_revoke_slave_inspector',
    text: 'Запрос на отзыв инспектора',
  },
};

export const INSPECTOR_STATUS = {
  AGREED: {
    value: 'AGREED',
    text: 'Согласился',
  },
  REJECTED: {
    value: 'REJECTED',
    text: 'Отказался',
  },
  WITHOUT_ANSWER: {
    value: 'WITHOUT_ANSWER',
    text: 'Не ответил',
  },
  CANCELLED: {
    value: 'CANCELLED',
    text: 'Приглашение отозвано',
  },
  DELEGATED: {
    value: 'DELEGATED',
    text: 'Делегировал сотруднику',
  },
  REVOKED_BY_INSPECTOR_CHANGE: {
    value: 'REVOKED_BY_INSPECTOR_CHANGE',
    text: 'Приглашение отозвано из-за смены инспектора',
  },
}

export const USER_TYPES = {
  INITIATOR: {
    value: 'INITIATOR',
    text: 'Заявитель',
  },
  WORKSHOP_REVIEWER: {
    value:'WORKSHOP_REVIEWER',
    text: 'Согласующий',
  },
  REVIEWER: {
    value:'REVIEWER',
    text: 'Согласующий', // Администратор заявки
  },
  INSPECTOR: {
    value: 'INSPECTOR',
    text: 'Инспектор',
  },
  GUEST: {
    value: 'GUEST',
    text: 'Гость',
  },
};

export const INSPECTOR_TYPES = {
  EXTERNAL: {
    value: 'external',
    text: 'Внешний',
  },
  INTERNAL: {
    value: 'internal',
    text: 'Внутренний',
  },
}

export default {
  USER_TYPES,
  INSPECTOR_STATUS,
  APPLICATION_ACTIONS,
  APPLICATION_STATUSES,
  INSPECTOR_TYPES,
}
