<template>
  <VSelect
      dense
      clearable
      hide-details
      :items="items"
      :value="this.value"
      @input="(val) => $emit('input', val)"
  >
    <template v-slot:selection="{ item }">
      <span class="v-select__selection v-select__selection--comma">{{ item.text }}</span>
    </template>
  </VSelect>
</template>

<script>
export default {
  name: 'SelectSingle',
  props: {
    items: { type: Array },
    value: { type: String },
  },
  computed: {}
}
</script>

<style module lang="scss">
.item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
